.home {
    width: 100%;
    max-width: 150rem;
    margin: 0 auto;
    background-color: #ffffff;
  
    &__section {
      // padding: 1rem;
      overflow: hidden;
      padding-right: 30px;
    }
  }
  