.filters {
    display: flex;
    flex-direction: column;
    gap: 8px;
    padding: 16px;
  
    label {
      display: flex;
      align-items: center;
      font-size: 14px;
  
      input {
        margin-right: 8px;
      }
  
      &.disabled {
        color: #ccc; /* Gray out the text for disabled platforms */
        cursor: not-allowed;
      }
    }
  }
  