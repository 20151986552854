.products {
  display: flex; /* Flexbox for easy layout */
  flex-wrap: wrap; /* Wrap cards to the next line */
  // justify-content: space-between; /* Space out cards */
  // gap: 5px; /* Add space between cards */
  padding: 10px; /* Add padding around the container */


  // position: absolute;
  // position: relative;


  // z-index: 1;//check
  // margin-left: 0px !important;
  // margin-right: 0px !important;
  // display:inline-block;
  // align-items: stretch;
  // margin-top: 3rem;
  // overflow: hidden;
  // background-color: #ffffff;


  // background-color: #ffffff;
  // display: flex;
  // flex-wrap: wrap;
  // overflow-x:auto;
  // overflow-x:hidden;
  // background-color: #f0f0f0;
}

.add-to-cart-button {
  padding: 0.5rem 1rem;
  // background-color: var(--theme-color-1);
  background-color: rgb(47, 47, 255);
  // greenyellow
  cursor: pointer;
  border: 0.1rem solid #e2e2e2;
  outline: none;
  box-shadow: none;
  font-weight: bold;
}
