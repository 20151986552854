html {
  box-sizing: border-box;
  // Makes 1rem = 10px;
  font-size: 62.5%;
  
  * {
    box-sizing: inherit;
    // margin-right: 2px !important;
  }
}

body {
  color: var(--default-font-color);
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Helvetica, Arial, sans-serif, Apple Color Emoji,
    Segoe UI Emoji;
  font-size: var(--h5-size);
  background-color: var(--body-background-color);
}

html,
head,
body,
#root,
.page-container {
  height: 100%;
  background-color: #ffffff;
}

a {
  text-decoration: none;
  color: inherit;
}
