.product-card-wrapper {
    // width: 33.33%;
    // width: 50%;
    padding-left: 5px !important;
    padding-right: 5px !important;
    min-height: 200vh;
    // padding: 0 0 0 110px;
    // position: absolute;
    // width: 50%;
    // margin-left: 10%;
  
    //Needed
    position: relative;
    border:none;
    // margin-bottom: 24px;
    // width: 100%;
    // padding: 1rem;
  }
  
  // @media (min-width: 600px)
  
  .muiContainer-root {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
  .Container {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
  
  // img {
  //   width: 20rem;
  //   height: 20rem;
  //   object-fit: contain;
  //   object-position: center;
  //   border: 5px solid #a2a6ac;
  //   border-radius: 2px;
  // }
  
  .product-card {




    width: 100%; /* Ensure the card takes up the container width */
  height: auto; /* Card height will adjust based on content */
  max-width: 250px; /* Set a maximum width for consistency */
  background-color: #ffffff; /* White background for clarity */
  border: 1px solid #ddd; /* Add a light border for structure */
  border-radius: 8px; /* Slightly rounded corners */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Subtle shadow for better visuals */
  display: flex;
  flex-direction: column; /* Align items vertically */
  justify-content: space-between; /* Space out content */
  overflow: hidden; /* Hide anything overflowing the card */
  // text-align: center; /* Center-align text */
  margin: 10px auto; /* Add space between cards */
  padding: 0px !important;

  &__outer-price {
    padding: 3px !important;
  }

  &__price-container {
    display: flex;
    justify-content: space-between; /* Align items to the left and right */
    // align-items: center;
    margin-bottom: 0.5rem;
    padding: 0px 1px !important; /* Adjust padding to reduce space */
    padding-left: 0px !important;
  }

  &__price {
    font-weight: 600;
    font-size: 1.5rem !important; /* Increase font size */
    text-align: left !important; /* Align text to the left */
    margin-right: auto !important; /* Ensure it stays on the left */
    padding: 0px !important;
  }

  &__discount {
    font-weight: 600 !important;
    font-size: 1.5rem !important; /* Increase font size */
    // color: var(--hint-font-color);
    color: red;
    // color: 'red';
     text-align: right !important; /* Align text to the right */
     margin-left: auto !important; /* Ensure it stays on the right */
  }



  


    // padding: 1rem;
    // padding-left: 2px !important;
    // padding-right: 2px !important;

    // display: 'flex';
    // justify-content: 'center';
    // align-items: 'center';
    // border:none;
    // background-color: #ffffff;


    // min-height: 20vh;
    // height: 85vh;
    // width: 45vh;
    // minHeight: "200vh";
    // margin-left: 0px !important;
    // margin-right: 0px !important;
    // padding: 1.5rem;
    // box-shadow: var(--default-shadow);

    // height: 200px; /* Fixed height for the card */
    // display: flex;
    // justify-content: center;
    // align-items: center;
    // background-color: #f8f8f8; /* Light background for whitespace */
    // overflow: hidden; /* Hide any image overflow */
  
    &__title {
      height:"10rem" !important;
      font-weight: bolder;
      font-weight: 600;
      word-wrap: break-word;
      white-space: pre-wrap;
      word-break: break-word;
      overflow: auto;
      margin-bottom: 0.5rem;
      height: 68px;
      padding: 4px !important;
      // height:"15px"
    }
    
    // &__price {
    //   display: flex;
    // // align-items: center;
    //   font-weight: 600;
    //   font-size: var(--h4-size);
    //   align-items: flex-end;
    //   margin-bottom: 0.5rem;
    // justify-content: space-between;
    // // justify-content: flex-start; /* Align items to the left */
    //   padding: 0px !important;
    //   // padding: 3px;
    // }
    
    // &__amount {
    //   // font-weight: bold;
    //   font-weight: 600;
    //   font-size: "15px";
    //   // margin-right: 0.5rem;
    //   // padding: 5px;
      
    //   &--discount {
    //     font-weight: 600;
    //     // font-weight: normal;
    //     color: var(--hint-font-color);
    //     text-decoration: line-through;
    //     margin-left: 0.3rem;
    //   }
    // }
  
    &__rating {
      display: flex;
      align-items: center;
    }
  
    &__rating-count {
      margin-left: 0.5rem;
      color: var(--hint-font-color);
    }
  
    &__gallery {
      // display: block;
      // height: 25rem;
      
      // width: auto;
      // height: 20rem;
      // align-items: center;
      // justify-content: center;

      // margin: 1rem 0;
      // width: 75% !important;
      // height: 10rem !important;
      // margin-left: 12.5%;
      // object-fit: cover;
      // overflow: hidden;
      
      // width: 100%;
      // height:100%;
      // max-width: 50%;

      // height: 200px; /* Fixed height */
      // width: 100%; /* Full width of the container */
      // object-fit: cover; /* Maintain aspect ratio and cover the container */
      // object-position: center;

      // height: 200px; /* Fixed height */
      // width: auto; /* Dynamic width based on aspect ratio */
      // max-width: 100%; /* Ensure it doesn't exceed container width */
      // object-fit: contain; /* Maintain aspect ratio and contain within the container */
      // object-position: center; /* Center the image */
      // display: block;
      // margin-left: auto;
      // margin-right: auto;

      // height: 200px; /* Fixed height for all images */
      // width: auto; /* Dynamic width based on the aspect ratio */
      // max-width: 100%; /* Ensure the image doesn’t overflow the card */
      // object-fit: contain; /* Ensure the entire image is visible, maintaining aspect ratio */
      // object-position: center; /* Center the image within the card */
      // display: block; /* Ensure the image is treated as a block element */
      // margin-left: auto;
      // margin-right: auto; /* Center the image horizontally */
      // background-color: #f8f8f8; /* Add a light background to fill whitespace */
    
      // max-height: 100%; /* Ensure the image height does not exceed the card height */
      // max-width: 100%; /* Ensure the image width scales dynamically */
      // object-fit: contain; /* Maintain aspect ratio and ensure the image is fully visible */
      // display: block;

  //     height: 200px; /* Fixed height for images */
  // width: auto; /* Dynamic width based on aspect ratio */
  // max-width: 100%; /* Ensure it doesn’t exceed card width */
  // object-fit: contain; /* Ensure the entire image is visible */
  // background-color: #f8f8f8; /* Light background to fill space */
  // display: block;
  // margin: 0 auto; /* Center the image horizontally */

  height: 200px; /* Fixed height for consistency */
  width: auto; /* Width adjusts dynamically based on aspect ratio */
  max-width: 100%; /* Ensure it doesn’t exceed the card/container width */
  object-fit: cover; /* Ensure the full image is visible */
  object-position: center; /* Center the image within the container */
  background-color: #f8f8f8; /* Optional: Add a light background to fill empty space */
  display: block;
  margin: 0 auto; /* Center the image horizontally */


    }

    @media (max-width: 600px) {
      .product-card__title {
        height: 95px; /* Adjust height for smaller screens */
      }
      .product-card__gallery {
        height: 150px; /* Adjust height for smaller screens */
      }
    }
    
    @media (min-width: 601px) and (max-width: 1024px) {
      .product-card__gallery {
        height: 180px; /* Adjust height for medium screens */
      }
    }
    
    @media (min-width: 1025px) {
      .product-card__title {
        height: 95px; /* Adjust height for smaller screens */
      }
      .product-card__gallery {
        height: 200px; /* Fixed height for larger screens */
      }
    }
  
    &__image {
      object-fit: contain;
      object-position: center;
      height: 100%;
    }
  
    &__actions {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  
    &__button {
      padding: 0.5rem 1rem;
      // background-color: var(--theme-color-1);
      // background-color: rgb(11, 38, 159);
      background-color: rgb(47, 47, 255);
      color: white;
      display:block ;
      position: bottom;
    //   position: relative;
      align-items: center;
      justify-content: center;
      padding-top: 5px;
      // background-color: greenyellow;
    }

    &__buttondiv {
      display: flex;
      justify-content: center;
      align-items: center;
      // align-content: center;
      // position: relative;
    }
  }
  
  .add-to-cart-button {
    padding: 0.5rem 1rem;
    // background-color: var(--theme-color-1);
    background-color: greenyellow;
    cursor: pointer;
    border: 0.1rem solid #e2e2e2;
    outline: none;
    box-shadow: none;
    font-weight: bold;
  }
  