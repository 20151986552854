// SearchPage.scss

.search-page-container {
    padding: 20px;
    background-color: #f5f5f5;
    border-radius: 8px;
    margin-top: 20px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .search-box,
  .category-dropdown,
  .price-range,
  .discount-range,
  .sort-dropdown,
  .prime-toggle,
  .advanced-filters {
    margin-bottom: 20px;
  }
  
  /* Add more specific styles for each component as needed */
  