.product-card-wrapper {
  // width: 33.33%;
  // width: 50%;
  padding-left: 5px !important;
  padding-right: 5px !important;
  // min-height: 20vh;
  // padding: 0 0 0 110px;
  // position: absolute;
  // width: 50%;
  // margin-left: 10%;

  //Needed
  position: relative;
  // margin-bottom: 24px;
  // width: 100%;
  // padding: 1rem;
  // height: 20vh;
}

// @media (min-width: 600px)

.MuiContainer-root {
  padding-left: 0px !important;
  padding-right: 0px !important;
  // min-height: 20px;
}
.Container {
  padding-left: 0px !important;
  padding-right: 0px !important;
  // min-height: 20vh;
}

// img {
//   width: 20rem;
//   height: 20rem;
//   object-fit: contain;
//   object-position: center;
//   border: 5px solid #a2a6ac;
//   border-radius: 2px;
// }

.product-card {
  // padding: 1rem;
  padding-left: 5px !important;
  padding-right: 5px !important;
  // min-height: 20vh;
  // margin-left: 0px !important;
  // margin-right: 0px !important;
  // padding: 1.5rem;
  background-color: #ffffff;
  box-shadow: var(--default-shadow);

  &__title {
    font-weight: bolder;
    word-wrap: break-word;
    white-space: pre-wrap;
    word-break: break-word;
    overflow: hidden;
    // margin-bottom: 0.5rem;
  }

  &__price {
    display: flex;
    font-size: var(--h4-size);
    align-items: flex-end;
    margin-bottom: 0.5rem;
  }
  
  &__amount {
    font-weight: bold;
    // font-size: var(--h3-size);

    &--discount {
      font-weight: normal;
      color: var(--hint-font-color);
      text-decoration: line-through;
      margin-left: 0.3rem;
    }
  }

  &__rating {
    display: flex;
    align-items: center;
  }

  &__rating-count {
    margin-left: 0.5rem;
    color: var(--hint-font-color);
  }

  &__gallery {
    display: flex;
    height: 12rem;
    // height: 20rem;
    margin: 1rem 0;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    // max-width: 50%;
  }

  &__image {
    object-fit: contain;
    object-position: center;
    height: 100%;
  }

  &__actions {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__button {
    padding: 0.5rem 1rem;
    // background-color: var(--theme-color-1);
    // background-color: rgb(11, 38, 159);
    background-color: rgb(47, 47, 255);
    color: white;
    position: relative;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    border: 0.1rem solid #e2e2e2;
    outline: none;
    box-shadow: none;
    font-weight: bold;
    align-items: center;
    // background-color: greenyellow;
  }
}

.add-to-cart-button {
  padding: 0.5rem 1rem;
  // background-color: var(--theme-color-1);
  background-color: greenyellow;
  cursor: pointer;
  border: 0.1rem solid #e2e2e2;
  outline: none;
  box-shadow: none;
  font-weight: bold;
}
