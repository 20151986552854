.error {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 1rem;

  &__message {
    text-align: center;
    font-size: var(--h4-size);
    font-weight: bold;
  }

  &__button {
    cursor: pointer;
    margin-top: 1rem;
    background-color: var(--theme-color-1);
    padding: 1rem;
    border-radius: var(--border-radius);
    color: var(--light-font-color);
    border: none;
    outline: none;
    box-shadow: none;
  }
}
