.information {
  line-height: 4.235;
  padding: 2rem 0 0 0;
  &__header {
    font-size: var(--h3-size);
    font-weight: bolder;
    padding-top: 30px;
  }

  &__category {
    color: var(--link-color);
    font-size: var(--h4-size);
  }

  &__label {
    color: var(--hint-font-color);
    font-size: var(--h4-size);
    font-weight: bolder;
  }
  
  &__price {
    display: inline-block;
    // display: flex;
    font-size: var(--h6-size);
    // font-weight: bolder;
    font-weight: 600;
    
  }

  &__amount {
    // font-weight: bold;
    font-weight: 600;
    font-size: "15px";
  }

  &__specs {
    padding-top: 2rem;
  }

  &__features {
    padding-top: 2rem;
  }

  &__rating {
    display: flex;
    // padding: 0.5rem 0;
    border-bottom: 1px solid var(--border-color);
    padding: 1rem 0 2rem 2rem;
  }

  &__pricing {
    // padding: 1rem 0 2rem 2rem;
  }

  &__spec-header {
    font-size: var(--h4-size);
    font-weight: bold;
  }

  &__rating-label {
    color: var(--link-color);
    padding-left: 1rem;
  }
}
.strikethrough {
  text-decoration: line-through;
}

.add-to-cart-button {
  padding: 0.5rem 1rem;
  // background-color: primary;
  // background-color: var(--theme-color-1);
  // background-color: greenyellow;
  cursor: pointer;
  border: 1rem solid #e2e2e2;
  outline: none;
  box-shadow: 10px;
  font-weight: bold;
}

