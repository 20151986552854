.logo {
  padding: 0.5rem 0.5rem 0 0.5rem;
  // padding: 1.5rem 1.5rem 0 1rem;
  position: relative;
  

  &__img {
    height: calc(var(--header-height) - 2rem);
    max-width: 100%;
  }

  &__country {
    position: absolute;
    font-size: var(--h6-size);
    right: 0;
    bottom: 2.2rem;
    font-weight: bold;
  }
}
