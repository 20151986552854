.slider {
  display: block;
  width: 100%;
  height: 40rem;

  &__listing {
    list-style-type: none;
  }

  &__list {
    cursor: pointer;
    padding: 0 0rem 1rem 1rem;

    img {
      width: 100%;
      // width: 4rem;
      // height: 100%;
      height: 40rem;
      object-fit: contain;
      object-position: center;
      border: 1px solid #a2a6ac;
      border-radius: 2px;

      &:hover {
        border-color: #e77600;
      }
    }
  }

  &__right {
    // padding: 2rem;
    img {
      width: 100%;
      // height: 40%;
      // width: 40rem;
      height: 45rem;
      object-fit: contain;
      object-position: center;
    }
  }
}
