.product {
  padding-top: 50px;
  // display: block;
  display: flex;
  // padding: 2rem;
  background-color: white;
  height: 100%;
  // margin-bottom: 20px;
  // margin-top: 20px;
}
.product-img {
  display: block;
  width: 100%;
  height: 100%;
}
.product-info {
  display: block;
  width: 100%;
  height: 100%;

}
