.header {
  height: var(--header-height);
  // background-color: rgb(47, 200, 255);
  background-color: rgb(47, 47, 255);
  // background-color: var(--theme-color-2);
  color: var(--light-font-color);
  position: fixed;
  width: 100%;
  z-index: 100;

  &__container {
    padding: 0 1rem;
    display: flex;
    align-items: center;
  }
}

// &__input {
__input {
  display: inline-block;
  background-color: #ffffff;
  color: var(--default-font-color);
  font-size: var(--h5-size);
  flex: 1 0 0;
}


.search {
  display: flex;
  align-items: stretch;
  flex: 1 0 0;
  margin: 1rem 1rem;
  border-radius: var(--border-radius);
  overflow: hidden;

  select,
  button,
  input {
    border: none;
    outline: none;
    box-shadow: none;
    padding: 0.8rem;
  }

  &__select {
    display: inline-block;
    background-color: #f2f2f2;
    border-right: 0.1rem solid #e2e2e2 !important;
    width: 14rem;
    font-size: var(--h6-size);
    text-align: center;
  }

  &__input {
    display: inline-block;
    background-color: #ffffff;
    color: var(--default-font-color);
    font-size: var(--h5-size);
    flex: 1 0 0;
  }

  &__button {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: greenyellow;
    // background-color: var(--theme-color-1);
    border-top-right-radius: var(--border-radius);
    border-bottom-right-radius: var(--border-radius);
  }
}

.add-to-cart-button {
  padding: 0.5rem 1rem;
  // background-color: var(--theme-color-1);
  background-color: greenyellow;
  cursor: pointer;
  border: 0.1rem solid #e2e2e2;
  outline: none;
  box-shadow: none;
  font-weight: bold;
}

.submit-button {
  // padding-top: 15px;
  margin-top: -12px !important;
  // margin-bottom: 15px !important;
  // padding: 2px !important;
  // padding: 2px !important;
  // color: blue($color: greenyellow);
}



